const Actions = {
    CHANGE_TITLE: 'CHANGE_TITLE',
    SET_ROUTE_LIST_ORIGIN: 'SET_ROUTE_LIST_ORIGIN',
    SET_ROUTE_PRESENTATION: 'SET_ROUTE_PRESENTATION',
    SET_GO_BACK_URL: 'SET_GO_BACK_URL',
    SET_GLOBAL_ERROR: 'SET_GLOBAL_ERROR',
    CHANGE_LANG: 'CHANGE_LANG',
    CONNEXION_CHANGE: 'CONNEXION_CHANGE',
    UNLOCK_GUIDEBOOK: 'UNLOCK_GUIDEBOOK',
    STORE_TOKEN: 'STORE_TOKEN',
    LOCK_GUIDEBOOK: 'LOCK_GUIDEBOOK',
    ADD_SAVED_ROUTE: 'ADD_SAVED_ROUTE',
    REMOVE_SAVED_ROUTE: 'REMOVE_SAVED_ROUTE',
    TOGGLE_INSTALL_MESSAGE: 'TOGGLE_INSTALL_MESSAGE',
    CHANGE_BOTTOM_NAVIGATION: 'CHANGE_BOTTOM_NAVIGATION',
    SHOW_FULLSCREEN_SPINNER: 'SHOW_FULLSCREEN_SPINNER',
    HIDE_FULLSCREEN_SPINNER: 'HIDE_FULLSCREEN_SPINNER',
    SHOW_MENU_NAV_BAR: 'SHOW_MENU_NAV_BAR',
    SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
    SET_SEARCH_QUERY_TERM: 'SET_SEARCH_QUERY_TERM',
    SET_SEARCH_GUIDEBOOK_QUERY: 'SET_SEARCH_GUIDEBOOK_QUERY',
    SET_MAP_VIEWPORT: 'SET_MAP_VIEWPORT',
    USER_SET_EMAIL: 'USER_SET_EMAIL',
    USER_SET_USER: 'USER_SET_USER',
    USER_SET_TOKEN: 'USER_SET_TOKEN',
    SET_CART: 'SET_CART',
    UPDATE_BOOKMARK: 'UPDATE_BOOKMARK',
}

export default Actions;