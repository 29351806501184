import Actions from "../config/actions";

let nextTodoId = 0

export const addTodo = text => ({
    type: 'ADD_TODO',
    id: nextTodoId++,
    text
})
export const setVisibilityFilter = filter => ({
    type: 'SET_VISIBILITY_FILTER',
    filter
})
export const toggleTodo = id => ({
    type: 'TOGGLE_TODO',
    id
})
export const VisibilityFilters = {
    SHOW_ALL: 'SHOW_ALL',
    SHOW_COMPLETED: 'SHOW_COMPLETED',
    SHOW_ACTIVE: 'SHOW_ACTIVE'
}

export const changeTitle = title => ({
    type: Actions.CHANGE_TITLE,
    title
});

export const changeBottomNavigation = bottomNavigationItems => ({
    type: Actions.CHANGE_BOTTOM_NAVIGATION,
    bottomNavigationItems
});

export const showMenuNavBar = show => ({
    type: Actions.SHOW_MENU_NAV_BAR,
    show
});

export const updateBookmark = bookmark => ({
    type: Actions.UPDATE_BOOKMARK,
    bookmark
});

export const changeLanguage = lang => ({
    type: Actions.CHANGE_LANG,
    lang
});

export const setRouteListOrigin = (routeListOrigin) => ({
    type: Actions.SET_ROUTE_LIST_ORIGIN,
    routeListOrigin
});

export const setRoutePresentation = (routePresentation) => ({
    type: Actions.SET_ROUTE_PRESENTATION,
    routePresentation
});

export const setStateGoBackUrl = (url, keepLink = false) => ({
    type: Actions.SET_GO_BACK_URL,
    url,
    keepLink
});
export const setGlobalError = hasError => ({
    type: Actions.SET_GLOBAL_ERROR,
    hasError
});

export const connexionStateChange = isOnline => ({
    type: Actions.CONNEXION_CHANGE,
    isOnline
});

export const unlockGuidebook = guidebookSlug => ({
    type: Actions.UNLOCK_GUIDEBOOK,
    guidebookSlug,
});

export const storeToken = (guidebookSlug, apiToken) => ({
    type: Actions.STORE_TOKEN,
    guidebookSlug,
    apiToken,
});

export const lockGuidebook = guidebookSlug => ({
    type: Actions.LOCK_GUIDEBOOK,
    guidebookSlug,
});

export const addSavedRoute = savedRoute => ({
    type: Actions.ADD_SAVED_ROUTE,
    route: savedRoute,
});
export const removeSavedRoute = savedRoute => ({
    type: Actions.REMOVE_SAVED_ROUTE,
    route: savedRoute,
});
export const toggleInstallMessage = (showInstallMessage, clickInstallMessage = false) => ({
    type: Actions.TOGGLE_INSTALL_MESSAGE,
    showInstallMessage: showInstallMessage,
    clickInstallMessage: clickInstallMessage,
});

export const showFullscreenSpinner = () => ({
    type: Actions.SHOW_FULLSCREEN_SPINNER
});
export const hideFullscreenSpinner = () => ({
    type: Actions.HIDE_FULLSCREEN_SPINNER
});

export const setSearchQuery = (screen, query) => ({
    type: Actions.SET_SEARCH_QUERY,
    screen,
    query,
});

export const setSearchQueryTerm = (term) => ({
    type: Actions.SET_SEARCH_QUERY_TERM,
    term,
});

export const setSearchGuidebookQuery = (guidebookQuery) => ({
    type: Actions.SET_SEARCH_GUIDEBOOK_QUERY,
    guidebookQuery,
});


export const setMapViewport = (screen, viewport) => ({
    type: Actions.SET_MAP_VIEWPORT,
    screen,
    viewport,
});

export const setCart = (cart) => ({
    type: Actions.SET_CART,
    payload: cart,
});