import {getUrl, PWAUrls} from "../config/urls";
import Constants from "../config/constants";
import {getUnixTime, isAfter, isValid, subDays} from "date-fns";

const IS_DEV = true;

const logInfo = (method, text) => {
    // if (IS_DEV) {
        let currentDate = '[' + new Date().toUTCString() + '] INFO ';
        console.log(currentDate, method + ' - ', text);
    // }
};

const logDebug = (method, text) => {
    if (IS_DEV) {
        let currentDate = '[' + new Date().toUTCString() + '] DEBUG ';
        console.log(currentDate, method + ' - ', text);
    }
};

const logError = (method, text, serverLog = false) => {
    let currentDate = '[' + new Date().toUTCString() + '] ERROR ';
    console.log(currentDate, method + ' - ', text);
    // if (serverLog) {
    //     try {
    //         let trace = text.toString();
    //
    //         if (typeof text.stack !== 'undefined') {
    //             trace = text.stack;
    //         }
    //
    //         postUrl(ApiUrls.ERROR, {
    //             'trace': trace,
    //             'url': window.location.href,
    //             'state': localStorage.getItem('state'),
    //         }).then(r => {
    //             // console.log(r);
    //         });
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }
};

// const postUrl = (url, data) => {
//     logInfo('post(' + url + ') => ', data);
//     return fetch(url, {
//         method: 'post',
//         body: JSON.stringify(data)
//     }).then(function (response) {
//         logInfo('post(' + url + ')', response);
//         return response.json();
//     }).then(function (data) {
//         return data;
//         // ChromeSamples.log('Created Gist:', data.html_url);
//     });
// };

const isNumber = (value) => {
    if ((undefined === value) || (null === value)) {
        return false;
    }
    if (typeof value == 'number') {
        return true;
    }
    return !isNaN(value - 0);
}

const suitableMonthBackgroundColor = '#3661b6';
const suitableMonthColor = '#fff';
const samplePieMonthsData = {
    fr: [
        {name: 'Janvier', nameShort: 'Jan'},
        {name: 'Février', nameShort: 'Fév'},
        {name: 'Mars', nameShort: 'Mars'},
        {name: 'Avril', nameShort: 'Avr'},
        {name: 'Mai', nameShort: 'Mai'},
        {name: 'Juin', nameShort: 'Juin'},
        {name: 'Juillet', nameShort: 'Juil'},
        {name: 'Août', nameShort: 'Août'},
        {name: 'Septembre', nameShort: 'Sept'},
        {name: 'Octobre', nameShort: 'Oct'},
        {name: 'Novembre', nameShort: 'Nov'},
        {name: 'Décembre', nameShort: 'Déc'}
    ],
    en: [
        {name: 'January', nameShort: 'Jan'},
        {name: 'February', nameShort: 'Feb'},
        {name: 'March', nameShort: 'Mar'},
        {name: 'April', nameShort: 'Apr'},
        {name: 'May', nameShort: 'May'},
        {name: 'June', nameShort: 'Jun'},
        {name: 'July', nameShort: 'Jul'},
        {name: 'August', nameShort: 'Aug'},
        {name: 'September', nameShort: 'Sept'},
        {name: 'October', nameShort: 'Oct'},
        {name: 'November', nameShort: 'Nov'},
        {name: 'December', nameShort: 'Dec'}
    ],
};

const trimProductName = (name) => {
    return name.replace(" - Topo Papier", "").replace(" - Topo Virtuel", "").replace(" - Topo", "").replace(" - GV", "").replace(" - Site", "")
}

const sectorMonthsToPieData = (months, lang) => {
    let sampleData = [];
    sampleData[0] = {
        name: samplePieMonthsData[lang][0]['name'],
        nameShort: samplePieMonthsData[lang][0]['nameShort'],
        value: 1,
        backgroundColor: "#fff",
        color: "#212529",
        isSuitable: 0,
        order: 0
    };
    sampleData[1] = {
        name: samplePieMonthsData[lang][1]['name'],
        nameShort: samplePieMonthsData[lang][1]['nameShort'],
        value: 1,
        backgroundColor: "#fff",
        color: "#212529",
        isSuitable: 0,
        order: 1
    };
    sampleData[2] = {
        name: samplePieMonthsData[lang][2]['name'],
        nameShort: samplePieMonthsData[lang][2]['nameShort'],
        value: 1,
        backgroundColor: "#fff",
        color: "#212529",
        isSuitable: 0,
        order: 2
    };
    sampleData[3] = {
        name: samplePieMonthsData[lang][3]['name'],
        nameShort: samplePieMonthsData[lang][3]['nameShort'],
        value: 1,
        backgroundColor: "#fff",
        color: "#212529",
        isSuitable: 0,
        order: 3
    };
    sampleData[4] = {
        name: samplePieMonthsData[lang][4]['name'],
        nameShort: samplePieMonthsData[lang][4]['nameShort'],
        value: 1,
        backgroundColor: "#fff",
        color: "#212529",
        isSuitable: 0,
        order: 4
    };
    sampleData[5] = {
        name: samplePieMonthsData[lang][5]['name'],
        nameShort: samplePieMonthsData[lang][5]['nameShort'],
        value: 1,
        backgroundColor: "#fff",
        color: "#212529",
        isSuitable: 0,
        order: 5
    };
    sampleData[6] = {
        name: samplePieMonthsData[lang][6]['name'],
        nameShort: samplePieMonthsData[lang][6]['nameShort'],
        value: 1,
        backgroundColor: "#fff",
        color: "#212529",
        isSuitable: 0,
        order: 11
    };
    sampleData[7] = {
        name: samplePieMonthsData[lang][7]['name'],
        nameShort: samplePieMonthsData[lang][7]['nameShort'],
        value: 1,
        backgroundColor: "#fff",
        color: "#212529",
        isSuitable: 0,
        order: 10
    };
    sampleData[8] = {
        name: samplePieMonthsData[lang][8]['name'],
        nameShort: samplePieMonthsData[lang][8]['nameShort'],
        value: 1,
        backgroundColor: "#fff",
        color: "#212529",
        isSuitable: 0,
        order: 9
    };
    sampleData[9] = {
        name: samplePieMonthsData[lang][9]['name'],
        nameShort: samplePieMonthsData[lang][9]['nameShort'],
        value: 1,
        backgroundColor: "#fff",
        color: "#212529",
        isSuitable: 0,
        order: 8
    };
    sampleData[10] = {
        name: samplePieMonthsData[lang][10]['name'],
        nameShort: samplePieMonthsData[lang][10]['nameShort'],
        value: 1,
        backgroundColor: "#fff",
        color: "#212529",
        isSuitable: 0,
        order: 7
    };
    sampleData[11] = {
        name: samplePieMonthsData[lang][11]['name'],
        nameShort: samplePieMonthsData[lang][11]['nameShort'],
        value: 1,
        backgroundColor: "#fff",
        color: "#212529",
        isSuitable: 0,
        order: 6
    };

    for (let i = 0; i < months.length; i++) {
        try {
            sampleData[months[i]].isSuitable = 1;
            // sampleData[months[i] - 1].isSuitable = 1;
            sampleData[months[i]].backgroundColor = suitableMonthBackgroundColor;
            sampleData[months[i]].color = suitableMonthColor;
            // sampleData[months[i] - 1].color = suitableMonthColor;
        } catch (e) {
            logError(e);
        }
    }

    sampleData = sampleData.sort((a, b) => {
        return a.order - b.order;
    });

    return sampleData;
};

const goBack = (pathname = window.location.pathname, keepLink) => {

    if (pathname !== window.location.pathname || keepLink) {
        return pathname;
    }

    // let slugs = pathname.match(/^\/([^/]*)\/([^/]*)\/([^/]*)\/([^/]*)\/([^/]*)/);
    let slugs = pathname.match(/\/([a-zA-Z0-9-]{0,})*/g);
    // console.log(slugs);
    // let paths = pathname.substring(1).split('/');
    let url = window.location.pathname;

    if (slugs.length > 5) {
        while (slugs.length > 5) {
            slugs.pop();
        }
        url = slugs.join('');
    } else if (slugs.length === 5) {
        slugs.pop();
        url = slugs.join('');
    } else if (slugs.length > 2 && slugs.length <= 4) {
        while (slugs.length > 2) {
            slugs.pop();
        }
        url = slugs.join('');
    } else if (slugs.length === 2) {
        url = '/';
    } else {
        url = '/';
    }

    return url;
};

const getCurrentGuidebookSlug = () => {
    let pathname = window.location.pathname;

    let slugs = pathname.match(/\/([a-zA-Z0-9-]{0,})*/g);

    if (slugs.length >= 2) {
        // console.log('getCurrentGuidebookSlug: ', slugs[1].substring(1));
        if (slugs[1].length >= 2) {
            return slugs[1].substring(1);
        }
    }

    return null;
};

const cartExists = (cart) => {
    return cart !== null;
}

const cartHasItem = (cart, productCode) => {
    return cartExists(cart) && cart.items !== undefined && cart.items.filter(e => e.product.code === productCode).length > 0
}

const addToCart = () => {
    // grab current state
}

const getErrorsFromResponse = (response) => {
    if (!response?.data?.error) {
        return [];
    }

    return response.data.error.map((e) => e.message);
}

const getStoreRouteCacheTime = (strEndActivationDate) => {
    if (strEndActivationDate === 0 || strEndActivationDate === null || typeof strEndActivationDate === "undefined") {
        return Infinity;
    }

    const endActivationDate = new Date(strEndActivationDate.slice(0, 19));

    if (!isValid(endActivationDate)) {
        return Infinity;
    }

    if (isAfter(new Date(), endActivationDate)) {
        return -1;
    }

    return (getUnixTime(endActivationDate) - getUnixTime(new Date())) * 1000;
}

const isProductBuyable = (product) => {
    return product &&
        product.enabled &&
        isNumber(product.price) &&
        parseFloat(product.price) > 0
    ;
}

const getUserValidation = (user, id) => {
    const validations = user?.validations ? user.validations : [];
    return validations.find((v) => v.id === id);
}

const allowGuidebookActivation = (user, guidebook) => {
    const validation = getUserValidation(user, guidebook?.id);

    if (!validation) {
        return true;
    }

    const endValidationDate = new Date(validation.endValidationDate.slice(0, 19))
    const allowGuidebookActivationDate = subDays(endValidationDate, 15);

    return isAfter(new Date(), allowGuidebookActivationDate);
}

const isGuidebooksUnlocked = (user, guidebookIds) => {
    if (!user) {
        return false;
    }

    const guidebooks = user?.guidebooks ? user.guidebooks : [];

    for (const guidebookId of guidebookIds) {
        const validation = getUserValidation(user, guidebookId);
        const buyedGuidebook = guidebooks.find((g) => g.id === guidebookId);

        if (validation || buyedGuidebook) {
            return true;
        }
    }

    return false;
}

const isGuidebookUnlocked = (user, guidebook) => {
    const guidebooks = user?.guidebooks ? user.guidebooks : [];

    const validation = getUserValidation(user, guidebook?.id);
    const buyedGuidebook = guidebooks.find((g) => g.id === guidebook?.id);

    return validation || buyedGuidebook;
}

const isGuidebookBuyed = (user, guidebook) => {
    const guidebooks = user?.guidebooks ? user.guidebooks : [];

    return guidebooks.find((g) => g.id === guidebook?.id);
}

const getCliffTypeFromLocale = (locale) => {
    return locale === 'fr' ? Constants.TypeTopo.FALAISE_FR : Constants.TypeTopo.FALAISE_EN;
}

const getMultipitchTypeFromLocale = (locale) => {
    return locale === 'fr' ? Constants.TypeTopo.GRANDEVOIE_FR : Constants.TypeTopo.GRANDEVOIE_EN;
}

const getTypeFromListItem = (item, locale) => {
    if (item?.type === Constants.CLIFF) {
        return getCliffTypeFromLocale(locale);
    }
    if (item?.type === Constants.MULTIPITCH) {
        return getMultipitchTypeFromLocale(locale);
    }

    return null;
}

const getLinkFromListItem = (item, locale) => {
    if (!item.region?.slug) {
        console.error("Delete route has tick:", item);
        return "/";
    }

    if (item?.type === Constants.CLIFF) {
        let url = getUrl(PWAUrls.CLIFF, {lng: locale, slug: item.slug, slugRegion: item.region.slug});
        // if (includeQuery && item?.types && item.types.length > 1) {
        //     const urlParams = new URLSearchParams(window.location.search);
        //     if (urlParams.has('q')) {
        //         url += '?q=' + urlParams.get('q');
        //     }
        // }
        return url;
    }
    if (item?.type === Constants.MULTIPITCH) {
        return getUrl(PWAUrls.MULTIPITCH_ROUTE, {lng: locale, slug: item.slug, slugRegion: item.region.slug});
    }

    // if (item?.region && item?.slug) {
    //     const routeUrlPrefix = screenName === Constants.SCREEN_NAME.SEARCH ? 'search/' :
    //         screenName === Constants.SCREEN_NAME.MY_ROUTE ? 'routes/' : window.location.pathname + '/';
    //
    //     return routeUrlPrefix + getTypeFromListItem(item) + '/' + item.region.slug + '/' + item.slug;
    // }
    //
    // return null;
}

const getUrlSiteFromTicking = (ticking, locale) => {
    if (ticking.cliffRoute) {
        return getUrl(PWAUrls.SECTOR_ROUTE_DETAILS, {lng: locale, routeType: getCliffTypeFromLocale(locale), slugRegion: ticking.route.region.slug, slugGroupRoute: ticking.cliff.slug, slug: ticking.route.slug});
    } else {
        return getUrl(PWAUrls.MULTIPITCH_ROUTE, {lng: locale, routeType: getMultipitchTypeFromLocale(locale), slugRegion: ticking.route.region.slug, slug: ticking.route.slug});
    }
}

const hydrateSearchResult = (res, screenName, auth) => {

    if (!res?.data) {
        return res;
    }
    if (!res.data?.data) {
        return res.data;
    }

    const listActions = res.data.data.listActions;
    const productValidations = Array.isArray(res.data.data.productValidations) ? res.data.data.productValidations : Object.values(res.data.data.productValidations);

    res.data.data.hits.forEach((item, index, hits) => {
        //Handle ticking list action
        const listAction = listActions.find(f => f.routeId === item.id);

        if (listAction) {
            hits[index] = {...item, listAction: listAction};
        } else if (screenName !== Constants.SCREEN_NAME.MY_ROUTE) {
            //If array intersect
            //Keep some instead of include because of loose eguality (item.productIds is an array of strings)
            const productIntersectionArray = Array.isArray(productValidations) && item?.productIds
                ? item.productIds.filter(value => productValidations.some(v => v == value)) : [];

            if (productIntersectionArray.length === 0 && !isGuidebooksUnlocked(auth?.user, item.guidebookIds)) {
                hits[index] = {...item, listAction: {action: 'add_to_cart'}};
            } else {
                hits[index] = {...item, listAction: {action: 'my_route'}};
            }
        }
    });

    return res.data;
}

const flattenSearchResult = (data) => {
    if (!data?.pages) {
        return [];
    }

    let flatItems = [];
    for (let page of data.pages) {
        let hits = [];

        if (page?.hits) {
            hits = page.hits;
        }

        if (page?.data?.hits) {
            hits = page.data.hits;
        }

        if (hits.empty) {
            continue;
        }
        flatItems = flatItems.concat(hits);
    }

    return flatItems;
}

export {
    logInfo,
    logError,
    logDebug,
    sectorMonthsToPieData,
    goBack,
    getCurrentGuidebookSlug,
    cartExists,
    cartHasItem,
    addToCart,
    getErrorsFromResponse,
    getStoreRouteCacheTime,
    isProductBuyable,
    allowGuidebookActivation,
    isGuidebookUnlocked,
    isNumber,
    getCliffTypeFromLocale,
    getMultipitchTypeFromLocale,
    getTypeFromListItem,
    getLinkFromListItem,
    getUrlSiteFromTicking,
    flattenSearchResult,
    hydrateSearchResult,
    isGuidebookBuyed,
    trimProductName
};
