import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {ApiLanguages, ApiUrls} from "../config/urls";
import {useAuth} from "../context/auth-context";
import {toast} from "react-toastify";


const getProductName = (product) => {
    return product.name + (!product.virtual ? ' - Topo Papier ' : '');
}
export const fetchCart = (queryKey) => {
    let path = typeof queryKey === 'string' ? queryKey : queryKey[0];

    return axios.get(`${path}`).then((res) => res.data?.data ? res.data.data : res.data)
}

export function useCart(language) {
    const queryKey = [ApiUrls.SHOP_CART_GET.replace('{lng}', language)];
    const auth = useAuth();
    return useQuery({
        enabled: auth.isAuthenticated,
        queryKey: queryKey,
        queryFn: ({queryKey}) => fetchCart(queryKey)
    })
}

function updateCart(language, url, message = null) {
    const queryClient = useQueryClient();
    const auth = useAuth();
    const shopCartQueryKey = [ApiUrls.SHOP_CART_GET.replace('{lng}', language)];
    return useMutation({
        networkMode: 'always',
        mutationFn:(product) => axios.post(url.replace('{id}', product.id))
            .then((res) => {
                if (res.data.success === true) {
                    auth.setCart(res.data.data.cart)
                    queryClient.setQueryData(shopCartQueryKey, (prev) => {
                        return  res.data.data;
                    });
                    ApiLanguages.forEach((apiLanguage) => {
                        const queryKey = [ApiUrls.SHOP_CART_GET.replace('{lng}', apiLanguage)];
                        queryClient.invalidateQueries({queryKey: queryKey});
                    });
                    auth.clearReactQueryCache();
                    if (message) {
                        if (url.includes('add-to-cart')) {
                            toast.success(getProductName(product) + message);
                        } else {
                            toast.warning(getProductName(product) + message);
                        }
                    }
                } else {
                    console.log(res);
                    return res.data;
                }
            })
    });
}

export function useRemoveFromCart(language, message) {
    return updateCart(language, ApiUrls.SHOP_REMOVE_FROM_CART.replace('{lng}', language), message);
}

export function useAddToCart(language, message) {
    return updateCart(language, ApiUrls.SHOP_ADD_TO_CART.replace('{lng}', language), message);
}

export function useShippingMethod(language, shippingMethod) {
    const queryClient = useQueryClient();
    const shopCartQueryKey = [ApiUrls.SHOP_CART_GET.replace('{lng}', language)];
    return useMutation({
            networkMode: 'always',
            mutationFn:(shippingMethod) => axios.post(ApiUrls.CART_SET_SHIPPING_METHOD.replace('{lng}', language),
                {'shipping-method': shippingMethod})
                .then((res) => {
                    console.log(res.data);
                    if (res.data.success === true) {
                        queryClient.setQueryData(shopCartQueryKey, (prev) => {
                            return  res.data.data;
                        });
                    } else {
                        console.log(res);
                        return res.data;
                    }
                })
            ,

            onError: (error, _newFlashMessage, rollback) => {
                // console.error(error);
                // if (typeof error.response.data.error !== "undefined") {
                //     setServerErrors(error.response.data.error);
                // }

                if (rollback) rollback()
            },
            onSuccess: async (updateFlashMessage) => {
                // for (const apiLanguage of ApiLanguages) {
                //     await queryClient.invalidateQueries({queryKey: [flashMessageListQueryKey]})
                // }
            },
        }
    )
}
